import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";

export default function Teams() {
  return (
    <div className="Datenschutzerklärung">
      <div className="dschutz1">
        <h1 className="titled">TEAMS</h1>
        <h2 className="titled">!Hier kommen Sie zu unseren akutellen Teams!</h2>
        <h3 className="Mannschaften">
          <Link
                  to="/Jugendmannschaften">
                    Jugendmannschaften
          </Link> 
        </h3 >
            
        <h3 className="Mannschaften">
          <Link
              to="/Kampfmannschaft">
              Kampfmannschaft
          </Link>
        </h3> 
      </div>
    </div>
  );
}
