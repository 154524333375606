import React from "react";
import "../../App.css";
import "./Datenschutzerklärung.css";

export default function Datenschutzerklärung() {
  return (
    <div className="Datenschutzerklärung">
      <div className="dschutz1">
        <h1 className="titled">Datenschutzerklärung</h1>
        <p>
          Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
          Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
          gesetzlichen Bestimmungen (DSGVO, TKG 2003).In diesen
          Datenschutzinformationen informieren wir Sie über die wichtigsten
          Aspekte der Datenverarbeitung im Rahmen unserer Website.
        </p>
        <br></br>
        <h2 className="titled">Kontakt mit uns</h2>
        <p>
          Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns
          aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der
          Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
          gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
          weiter.&nbsp;
        </p>
        <br></br>
        <h2 className="titled">Cookies</h2>
        <p>
          Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um
          kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
          abgelegt werden. Sie richten keinen Schaden an.{" "}
        </p>
        <br></br>
        <p>
          Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten.
          Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
          löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch
          wiederzuerkennen.
        </p>
        <br></br>
        <p>
          Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so
          einrichten, dass er Sie über das Setzen von Cookies informiert und Sie
          dies nur im Einzelfall erlauben.{" "}
        </p>
        <br></br>
        <p>
          Bei der Deaktivierung von Cookies kann die Funktionalität unserer
          Website eingeschränkt sein.
        </p>
        <br></br>
        <h2 className="titled">Web-Analyse</h2>
        <p>
          Es werden Cookies verwendet, die eine Analyse der Benutzung der
          Website durch Ihre Benutzer ermöglicht. Die dadurch erzeugten
          Informationen werden auf den Server des Anbieters übertragen und dort
          gespeichert.
        </p>
        <br></br>
        <p>
          Sie können dies verhindern, indem Sie Ihren Browser so einrichten,
          dass keine Cookies gespeichert werden.{" "}
        </p>
        <br></br>
        <p>
          Ihre IP-Adresse wird erfasst, aber umgehend durch Löschung der letzten
          8 Bit pseudonymisiert. Dadurch ist nur mehr eine grobe Lokalisierung
          möglich.
        </p>
        <br></br>
        <p>
          Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen
          des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit f (berechtigtes
          Interesse) der DSGVO.{" "}
        </p>
        <br></br>
        <h2 className="titled">Ihre Rechte</h2>

        <p>
          Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich
          die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
          Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben,
          dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
          oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise
          verletzt worden sind, können Sie sich bei der uns{" "}
          <em>office@blue-devils.eu</em> oder der Datenschutzbehörde beschweren.
        </p>
        <br></br>
        <h2 className="titled">
          Sie erreichen uns unter folgenden Kontaktdaten:{" "}
        </h2>
        <table className="tablet">
          <tr>
            <td className="tabled">AFC Blue Devils</td>
          </tr>
          <tr>
            <td className="tabled">vertreten durch Obmann Michael Köhlmeier</td>
          </tr>
          <tr>
            <td className="tabled">Markus-Sittikus-Straße 6c</td>
          </tr>
          <tr>
            <td className="tabled">6845 Hohenems</td>
          </tr>
          <tr>
            <td className="tabled">Österreich</td>
          </tr>
          <tr>
            <td className="tabled">office@blue-devils.eu</td>
          </tr>
        </table>
        <br></br>
      </div>
    </div>
  );
}
