import React from "react";
import "../../App.css";
import "./Teams.css";

export default function U18() {
  return (
    <div className="Team">
      <div className="Teamname">
        <h1 className="titled">DAS U18-TEAM</h1>
      </div>
      <h2 className="titled">SPIELERLISTE </h2>
      <div className="spielerliste">
        <table className="players">
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
          </tr>
          <tr>
            <td>2</td>
            <td>Valentin</td>
            <td>Hopfner</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Jonas</td>
            <td>Madlener</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Marc</td>
            <td>Kemter</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Yves</td>
            <td>Hoscheck</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Mauro</td>
            <td>Pichler</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Elias</td>
            <td>Pfeiffer</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Benedikt</td>
            <td>Koch</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Luca</td>
            <td>Prantl</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Linus</td>
            <td>Martin</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Sebastian</td>
            <td>Fink</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Levin</td>
            <td>Wieser</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Paul</td>
            <td>Sonderegger</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Tristan</td>
            <td>D'Errico</td>
          </tr>
          <tr>
            <td>23</td>
            <td>Marco</td>
            <td>Hammerer</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Sarah</td>
            <td>Holdermann</td>
          </tr>
          <tr>
            <td>44</td>
            <td>Florian</td>
            <td>Tuppen</td>
          </tr>
          <tr>
            <td>51</td>
            <td>Mustafa</td>
            <td>Güven</td>
          </tr>
          <tr>
            <td>55</td>
            <td>Lars</td>
            <td>Amann</td>
          </tr>
          <tr>
            <td>57</td>
            <td>Kevin</td>
            <td>Limmen</td>
          </tr>
          <tr>
            <td>58</td>
            <td>Emelin</td>
            <td>Jusic</td>
          </tr>
          <tr>
            <td>60</td>
            <td>Armin</td>
            <td>Jusic</td>
          </tr>
          <tr>
            <td>66</td>
            <td>Mathias</td>
            <td>Rupp</td>
          </tr>
          <tr>
            <td>69</td>
            <td>Justin</td>
            <td>Paar</td>
          </tr>
          <tr>
            <td>72</td>
            <td>Julian</td>
            <td>Holdermann</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
