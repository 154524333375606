import React from "react";
import "../../App.css";

export default function Gameday() {
  return (
    <div className="Datenschutzerklärung">
      <div className="dschutz1">
        <img src="images/gameday.jpg" alt="gameday"></img>
      </div>
    </div>
  );
}
