import React from 'react'
import '../../App.css'

export default function Vorstand() {
  return (
    <div ClassName="Vorstand">
    <h1> Vorstand </h1>
        
        <p> Präsident: Michael Köhlmeier</p>
        <p> Vize-Präsident / General Manager: Christoph Piringer</p>
        <p> Athletic Manager / HeadCoach: Tyler Harlow</p>
        <p> Offense Coach: Patrick Fitzgerald</p>
        <p> LineCoach Manuel Schabel</p>

        
    </div>
    
 
  )
}


