import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
  return (
    <div className="cards">
      <div className="cards__container">
        <h1 className="tryouts">TRYOUTS</h1>
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem src="images/tryouts1.jpg" label="TRYOUTS" />

            <CardItem src="images/tryouts2.jpg" label="JOIN US!" />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
