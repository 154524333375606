import React from "react";
import "../../App.css";


export default function FAQ() {

  
  return (
    <>
     <img className="FAQimage" src="images/FAQ_Icon.png" alt="FAQ_Icon"></img>
      <br></br>
      <p className="FAQtextquestion">Wie kann ich anfangen Football zu spielen?</p>
      <p className="FAQtextanswer">Komm auf die Probetrainings oder einfach auf ein Training von uns. Am besten davor an die <a href="mailto:office@blue-devils.at">office@blue-devils.at</a> schreiben.</p>
      <br></br>
      <p className="FAQtextquestion">Wo kann ich auf Social Media die Blue Devils verfolgen?</p>
      <p className="FAQtextanswer">Die Cineplexx Blue Devils sind sehr aktiv auf Social Media. Wir sind zu finden auf Facebook, Instagram und YouTube.</p>
      <br></br>
      <p className="FAQtextquestion">Wo spielen und trainieren die Blue Devils?</p>
      <p className="FAQtextanswer">Im Herrenriedstadion in Hohenems (Markus-Sittikus-Straße 8, 6845 Hohenems), trainieren und bestreiten die Cineplexx Blue Devils ihre Heimspiele.</p>
      <br></br>
      <p className="FAQtextquestion">Wenn ich Football spielen möchte, welche Voraussetzungen benötige ich?</p>
      <p className="FAQtextanswer">Ehrlich gesagt sind keine sonderlichen Voraussetzungen nötig, um mit Football anzufangen. 
        Unser Sport bietet Möglichkeiten für jedermann: egal ob klein oder groß, leicht oder schwer.</p>
      <br></br>
    </>
  );
  }
