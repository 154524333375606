import React, { Component } from "react";
import axios from "axios";

class AFLTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    axios
      .get(
        `https://api.hockeydata.net/js/?los_americanfootball?api_key=$c9f6cfa207237641f117eaf0848e9ff1`
      )
      .then((res) => {
        this.setState({ data: res.data });
      });
  }

  render() {
    return (
      <table>
        <thead>
          <tr>
            <th>Team</th>
            <th>Wins</th>
            <th>Losses</th>
            <th>Draws</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((team) => (
            <tr key={team.id}>
              <td>{team.name}</td>
              <td>{team.wins}</td>
              <td>{team.losses}</td>
              <td>{team.draws}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default AFLTable;
