import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import Cards from "../Cards";
import InstagramFeed from "../InstagramFeed";
import { Component } from "react";
import AFLTable from "../AFLTable";

class Home extends Component {
  render() {
    return (
      <div>
        <HeroSection />
        <Cards />
      </div>
    );
  }
}

export default Home;
