import React from "react";
import "../../App.css";
import "./Teams.css";

export default function U16() {
  return (
    <div className="Team">
      <div className="Teamname">
        <h1 className="titled">DAS U16-TEAM</h1>
      </div>
      <h2 className="titled">SPIELERLISTE </h2>
      <div className="spielerliste">
        <table className="players">
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
          </tr>
          <tr>
            <td>4</td>
            <td>Maxi</td>
            <td>Schneider</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Marc</td>
            <td>Kemter</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Dennis</td>
            <td>Soucek</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Levi</td>
            <td>Spapens</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Elias</td>
            <td>Pfeiffer</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Benedikt</td>
            <td>Koch</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Sebastian</td>
            <td>Fink</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Tristan</td>
            <td>D'Errico</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Marco</td>
            <td>Hammerer</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Sarah</td>
            <td>Holdermann</td>
          </tr>
          <tr>
            <td>30</td>
            <td>Noah</td>
            <td>Dalaunay</td>
          </tr>
          <tr>
            <td>31</td>
            <td>Aaliyah</td>
            <td>Brunnerr</td>
          </tr>
          <tr>
            <td>40</td>
            <td>Kevin</td>
            <td>Limmen</td>
          </tr>
          <tr>
            <td>53</td>
            <td>Mustafa</td>
            <td>Güven</td>
          </tr>
          <tr>
            <td>55</td>
            <td>Lars</td>
            <td>Amann</td>
          </tr>
          <tr>
            <td>66</td>
            <td>Armin</td>
            <td>Jusic</td>
          </tr>
          <tr>
            <td>68</td>
            <td>Emelin</td>
            <td>Jusic</td>
          </tr>
          <tr>
            <td>83</td>
            <td>Theo</td>
            <td>Prantl</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
