import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";


export default function Jugendmannschaften() {
  return (
    <div className="Datenschutzerklärung">
      <div className="dschutz1">
        <h1 className="titled">JUGENDMANNSCHAFTEN</h1>

        <h2 className="Mannschaften">! Hier kommen Sie zu unseren akutellen Jugendteams !</h2>
        
        <h3 className="Mannschaften">
          <Link
                  to="/U16">
                    U16
          </Link> 
        </h3>
        <h3 className="Mannschaften">
          <Link
              to="/U18">
                U18
          </Link> 
        </h3>  
        
        <img className="Jugendmannschaftbild" src="images/Jugendteam.jpeg" alt="Jugendteam"></img>

    </div>
    </div>
    )
  }