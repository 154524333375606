import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import { Button } from "./Button";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link
            to="/"
            className="navbar-logo"
            onClick={() => {
              closeMobileMenu();
              window.scroll(0, 0);
            }}
          >
            CINEPLEXX BLUE DEVILS{" "}
            <img
              className="bluelogo"
              src="./images/Cineplexx_logo.png"
              alt="logo"
            ></img>
          </Link>

          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="dropdown">
              <Link
                className="droptext"
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                VEREIN
              </Link>
              <div class="dropdown-content">
                <Link
                  to="/Geschichte"
                  onClick={() => {
                    closeMobileMenu();
                    window.scroll(0, 0);
                  }}
                >
                  GESCHICHTE
                </Link>
                <Link
                  to="/Vorstand"
                  onClick={() => {
                    closeMobileMenu();
                    window.scroll(0, 0);
                  }}
                >
                  VORSTAND
                </Link>
              </div>
            </li>

            <li className="dropdown">
              <Link
                to="/Teams"
                className="droptext"
                onClick={() => {
                  closeMobileMenu();
                  window.scroll(0, 0);
                }}
              >
                TEAMS
              </Link>
              <div class="dropdown-content">
                <Link
                  to="/Jugendmannschaften"
                  onClick={() => {
                    closeMobileMenu();
                    window.scroll(0, 0);
                  }}
                >
                  JUGENDMANNSCHAFTEN
                </Link>
                
                <Link
                  to="/Kampfmannschaft"
                  onClick={() => {
                    closeMobileMenu();
                    window.scroll(0, 0);
                  }}
                >
                  KAMPFMANNSCHAFT
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link
                to="/News"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  window.scroll(0, 0);
                }}
              >
                NEWS
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  window.scroll(0, 0);
                }}
                href="https://www.hohenems-bluedevils.2k5.shop/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                SHOP{" "}
              </a>
            </li>
            <li className="nav-item">
              <Link
                to="/Gameday"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  window.scroll(0, 0);
                }}
              >
                GAMEDAY
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/FAQ"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  window.scroll(0, 0);
                }}
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
