import React from "react";
//import { Button } from "./Button";
import "./Footer.css";
import { Link } from "react-router-dom";
import sponsor1 from "../sponsoren/sponsor1.png";
import sponsor2 from "../sponsoren/sponsor2.png";
import sponsor3 from "../sponsoren/sponsor3.png";
import sponsor4 from "../sponsoren/sponsor4.png";
import sponsor5 from "../sponsoren/sponsor5.png";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">SPONSOREN</p>
        <div>
          <a href="https://www.cineplexx.at/" target="_blank" rel="noreferrer">
            <img className="sponsorimg" src={sponsor1} alt="cineplexx"></img>{" "}
          </a>
          <a href="https://vorkoster.at/" target="_blank" rel="noreferrer">
            <img className="sponsorimg" src={sponsor2} alt="vorkoster"></img>
          </a>
          <a href="https://www.vkw.at/" target="_blank" rel="noreferrer">
            <img className="sponsorimg" src={sponsor3} alt="vkw"></img>
          </a>
          <a href="https://www.generali.at/" target="_blank" rel="noreferrer">
            <img className="sponsorimg" src={sponsor4} alt="generali"></img>
          </a>
          <a href="https://www.hohenems.at/" target="_blank" rel="noreferrer">
            <img className="sponsorimg" src={sponsor5} alt="hohenms"></img>
          </a>
        </div>
      </section>
      <div className="footer-link">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Kontakt</h2>
            <a href="mailto:office@blue-devils.eu">
              Mail: office@blue-devils.eu
            </a>
          </div>

          <div className="footer-link-items">
            <h2>Links</h2>
            <Link
              to="/Datenschutzerklärung"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Datenschutzerklärung
            </Link>
          </div>

          <div className="footer-link-items">
            <h2>Social Media</h2>
            <a
              href="https://www.instagram.com/cineplexx_blue_devils"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/CineplexxBlueDevils/"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.hudl.com/team/v2/12800/Mens-Varsity-Football"
              target="_blank"
              rel="noreferrer"
            >
              Hudl
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link>
              <img
                className="bluelogo"
                src="./images/Cineplexx_logo.png"
                alt="logo"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              ></img>
            </Link>
          </div>
          <small className="website-rights">
            Cineplexx Blue Devils © 2022{" "}
          </small>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/CineplexxBlueDevils/"
              className="social-icon-link facebook"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/cineplexx_blue_devils"
              className="social-icon-link instagram"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCRgF6uwe8WSD624FqpSNfKg"
              className="social-icon-link youtube"
              target="_blank"
              rel="noreferrer"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
