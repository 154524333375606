import React from "react";
import "../../App.css";


export default function Geschichte() {
  return (
    <div className="Datenschutzerklärung">
      <div className="dschutz1">
        <h1 className="titled">Geschichte</h1>
        <p>
          Die Cineplexx Blue Devils wurden im Jahr 1999 als Nachwuchsverein
          gegründet. Ziel war es ein Nachwuchsprogramm neben den beiden
          existierenden Clubs in Vorarlberg zu etablieren.
        </p>
        <br></br>
        <p>
          Nach einigen Jahren in der Division II stiegen die Cineplexx Blue
          Devils im Jahre 2001 in die höchste österreichische Spielklasse (die
          AFL) auf. Diese stellte sich als nicht einfach zu meisternde Aufgabe
          heraus, trotzdem konnten sich die Blue Devils für sieben
          aufeinanderfolgende Jahre in der AFL halten.
        </p>
        <br></br>
        <p>
          Im Jahr 2007 konnten die Cineplexx Blue Devils trotz mehrerer
          Niederlagen in der höchsten österreichischen Spielklasse (AFL)
          internationale Erfolge verbuchen. Im EFAF Cup konnte das Finale duch
          Siege gegen Madrid, Coventry und Berlin erreicht werden. In diesem
          Finale trafen die Blue Devils auf die Graz Giants. Es konnte noch eine
          20:0 Halbzeitführung erreicht werden, diese konnte dann alledings
          nicht bis zum Ende gehalten werden, wodurch die Graz Giants das
          Finalspiel für sich entscheiden konnten. Im Jahr 2008 konnte dann das
          EFAL Halbfinale erreicht werden, ein Anschluss an den Erfolg von 2007
          gelang leider nicht.
        </p>
        <br></br>
        <p>
          Im Jahr 2009 spielten die Cineplexx Blue Devils in dem neuen
          Spielmodus Interdivision. Dieser Modus beinhaltete, dass zwei Teams
          (Cineplexx Blue Devils und Carinthian Black Lions) gegen alle AFL –
          Teams und gegen alle Division I teams spielten, aber niemals
          aufeinander trafen. Die Saison endete im Viertelfinale.
        </p>
        <br></br>
        <p>
          Im Anschluss an das Jahr 2009 entschieden sich die Cineplexx Blue
          Devils, in der Schweiz in der höchsten Spielklasse (NLA zu spielen).
          Hier konnte die Saison 2010 mit einem dritten Platz beendet werden.
        </p>
        <br></br>
        <p>
          Im Jahr 2011 wurde das Programm bei den Blue Devils erheblich
          vergrößert. Die im Jahr 2010 gegründete zweite Mannschaft konnte sich
          in der Division II etablieren. In der Schweizer Liga NLA konnte der
          dritte Platz erreicht werden. Ausserdem traten die Cineplexx Blue
          Devils noch im EFAF Cup an. Die Saison endete nach 30 absolvierten
          Spielen.
        </p>
        <br></br>
        <p>
          2012 entschieden die Blue Devils sich auf die Ligen in Österreich und
          international zu konzentrieren. Daher traten sie 2012 in der
          Österreichischen Division II, der CEFL und dem EFAF Cup an. Die Saison
          konnte dann in der Division II mit einem Sieg in der Iron Bowl nach
          einer Perfect Season beendet werden. International konnte in der CEFL
          ein dritter Platz erreicht werden, nachdem im Playoff gegen die
          Silverhawks aus Slowenien eine Niederlage eingesteckt werden musste.
          Als Highlight der Saison 2012 galt die Veranstaltung der C-Gruppen
          Europameisterschaft im American Football in Zusammenarbeit mit der
          Stadt Hohenems und der Stadt St. Gallen (CH).
        </p>
        <br></br>
        <p>
          Im Jahr 2013 stiegen die Blue Devils in die österreichische Division I
          auf. Zusätzlich nahmen die Devils am CEFL – Cup teil. Nach einigen
          Schwierigkeiten beim Season-Setup konnten die Blue Devils ein komplett
          neues Spielsystem etablieren. Dieses funktionierte dann auch sehr gut,
          die Cineplexx Blue Devils konnten die Saison als Sieger der Silverbowl
          nach einer weiteren Perfect Season beenden. Auch der CEFL – Cup konnte
          als Sieger (gegen die Belgrade Blue Dragons) beendet werden. Aufgrund
          der Zusammenstellung des Teams entschieden sich die Blue Devils im
          Jahr 2014, trotz des Sieges in der Division I, nicht in die AFL
          aufzusteigen. Das Team, mit dem die Perfect Season erreicht werden
          konnte bestand einerseits aus sehr jungen Spielern, andererseits aus
          älteren Spielern, die ihre aktive Karriere nach der Saison 2013
          beendeten.
        </p>
        <br></br>
        <p>
          Das Jahr 2014 stand im Zeichen des Aufbaus neuer Spieler. Der Coaching
          Staff war darauf ausgerichtet, junge Spieler möglichst an das nötige
          Niveau für die Division I bzw. in weiterer Folge dann für die AFL
          auszurichten. Daher konnte in diesem Jahr auch nur der sechste Platz
          in der Division I erreicht werden.
        </p>
        <br></br>
        <p>
          Im Jahr 2015 zeigte sich dann der Erfolg des Aufbauprogrammes im Jahr
          2014. Es konnte abermals die Silverbowl gewonnen werden. Nun
          entschieden sich die Blue Devils im Jahr 2016 an der AFL teil zu
          nehmen. Zusätzlich wurde für Rookies ein Team II gegründet, das 2016
          in der Division IV antreten wird.
        </p>
        <br></br>
        <p>
          Nach zwei erfolglosen Jahren in der AFL, spielten die Blue Devils zwei
          Jahre in der Division 1. Im Jahr 2019 wurden die Blue Devils in der
          Rolle des Underdogs überraschend Division 1 Meister und spielen ab der
          Saison 2020 wieder in der AFL.
        </p>
        <br></br>
      </div>
    </div>
  );
}
