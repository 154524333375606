import React from "react";
import "../../App.css";
import "./Teams.css";


export default function Kampfmannschaft() {
  return (
    <div className="Team">
      <div className="Teamname">
        <h1 className="titled">KAMPFMANNSCHAFT</h1>
      </div>
      <img className="Kampfmannschaftbild"  src="images/Kampfmannschaft.jpeg" alt="Kampfmannschaft"></img>
      <h2 className="titled">SPIELERLISTE </h2>
      <div className="spielerliste">
        <table className="players">
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th> Nationality </th>
            <th> Position </th>
          </tr>
          <tr>
            <td>2</td>
            <td>Leo</td>
            <td>Fuchs</td>
            <td>DE</td>
            <td>WR</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Lukas</td>
            <td>Lampert</td>
            <td>AT</td>
            <td>LB</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Leandro</td>
            <td>Jager</td>
            <td>AT</td>
            <td>WR</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Mathias</td>
            <td>Frick</td>
            <td>AT</td>
            <td>WR/DB</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Andreas</td>
            <td>Baumgartner</td>
            <td>AT</td>
            <td>DB</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Levin</td>
            <td>Wieser</td>
            <td>AT</td>
            <td>QB</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lukas</td>
            <td>Reiter</td>
            <td>AT</td>
            <td>DB</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Felix</td>
            <td>Rauch</td>
            <td>AT</td>
            <td>WR</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Patrick</td>
            <td>Welte</td>
            <td>AT</td>
            <td>WR/DB</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Jonas</td>
            <td>Madlener</td>
            <td>AT</td>
            <td>WR/DB</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Jonas</td>
            <td>Glamowski</td>
            <td>AT</td>
            <td>DB</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Paul</td>
            <td>Sonderegger</td>
            <td>AT</td>
            <td>DB</td>
          </tr>
          <tr>
            <td>21</td>
            <td>AAron</td>
            <td>Smith</td>
            <td>AT</td>
            <td>TE</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Chris</td>
            <td>Cajcmann</td>
            <td>AT</td>
            <td>RB/WR</td>
          </tr>
          <tr>
            <td>26</td>
            <td>Marcel</td>
            <td>Valdera</td>
            <td>AT</td>
            <td>RB/WR</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Eric</td>
            <td>Plangzer</td>
            <td>AT</td>
            <td>RB</td>
          </tr>
          <tr>
            <td>35</td>
            <td>David</td>
            <td>Liepert</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>40</td>
            <td>Ramon</td>
            <td>Schwarz</td>
            <td>CH</td>
            <td>LB/FB</td>
          </tr>
          <tr>
            <td>41</td>
            <td>Osama</td>
            <td>Alattari</td>
            <td>JO</td>
            <td>DB</td>
          </tr>
          <tr>
            <td>48</td>
            <td>Tim</td>
            <td>Broger</td>
            <td>LI</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>51</td>
            <td>Sandro</td>
            <td>Horvath</td>
            <td>AT</td>
            <td>OL</td>
          </tr>
          <tr>
            <td>52</td>
            <td>Patrick</td>
            <td>Kock</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>53</td>
            <td>Mustafa</td>
            <td>Cavus</td>
            <td>DE</td>
            <td>LB/DL</td>
          </tr>
          <tr>
            <td>57</td>
            <td>Kevin</td>
            <td>Hoch</td>
            <td>CH</td>
            <td>OL</td>
          </tr>
          <tr>
            <td>63</td>
            <td>Paul</td>
            <td>Janusauskas</td>
            <td>DE</td>
            <td>OL/DL</td>
          </tr>
          <tr>
            <td>66</td>
            <td>Andre</td>
            <td>Kasper</td>
            <td>DE</td>
            <td>OL/DL</td>
          </tr>
          <tr>
            <td>68</td>
            <td>Benedikt</td>
            <td>Furxer</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>69</td>
            <td>Erik</td>
            <td>Tschaikner</td>
            <td>AT</td>
            <td>OL</td>
          </tr>
          <tr>
            <td>71</td>
            <td>Kilian</td>
            <td>Penz</td>
            <td>AT</td>
            <td>OL</td>
          </tr>
          <tr>
            <td>72</td>
            <td>Lukas</td>
            <td>Brugger</td>
            <td>AT</td>
            <td>OL</td>
          </tr>
          <tr>
            <td>73</td>
            <td>Julian</td>
            <td>Holdermann</td>
            <td>AT</td>
            <td>OL</td>
          </tr>
          <tr>
            <td>75</td>
            <td>Joshua</td>
            <td>Wehinger</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>80</td>
            <td>Marcel</td>
            <td>Nague</td>
            <td>AT</td>
            <td>WR</td>
          </tr>
          <tr>
            <td>83</td>
            <td>Samuel</td>
            <td>Brunner</td>
            <td>AT</td>
            <td>WR</td>
          </tr>
          <tr>
            <td>85</td>
            <td>Timo</td>
            <td>Drexel</td>
            <td>AT</td>
            <td>WR/DB</td>
          </tr>
          <tr>
            <td>88</td>
            <td>Lucas</td>
            <td>Vögel</td>
            <td>AT</td>
            <td>WR/TE</td>
          </tr>
          <tr>
            <td>92</td>
            <td>Mathias</td>
            <td>Rupp</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>95</td>
            <td>Marco</td>
            <td>Sutterlüty</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>97</td>
            <td>Klemens</td>
            <td>Rupp</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
          <tr>
            <td>99</td>
            <td>Mathias</td>
            <td>Tschabrun</td>
            <td>AT</td>
            <td>DL</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
