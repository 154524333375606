import React from "react";
import "./HeroSection.css";
import { Button } from "./Button";
import "../App.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/Video3.mp4" autoPlay muted loop />
      <h1> CINEPLEXX BLUE DEVILS</h1>
      <p> What are you waiting for?</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          WATCH TRAILER{" "}
          <i
            className="far
                    fa-play-circle"
          />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
