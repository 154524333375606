import React from "react";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import News from "./components/pages/News";
import Canteen from "./components/pages/Canteen";
import Gameday from "./components/pages/Gameday";
import Verein from "./components/pages/Verein";
import FAQ from "./components/pages/FAQ";
import Teams from "./components/pages/Teams";
import Shop from "./components/pages/Shop";
import Datenschutzerklärung from "./components/pages/Datenschutzerklärung";
import Geschichte from "./components/pages/Geschichte";
import U16 from "./components/pages/U16";
import U18 from "./components/pages/U18";
import Kampfmannschaft from "./components/pages/Kampfmannschaft";
import Jugendmannschaften from "./components/pages/Jugendmannschaften";
import $ from "jquery";
import { useState } from "react";
import Vorstand from "./components/pages/Vorstand";

function App() {
  const [name, setName] = useState("");
  const [result, setResult] = useState("");

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Gameday" element={<Gameday />} />
          <Route path="/Canteen" element={<Canteen />} />
          <Route path="/News" element={<News />} />
          <Route path="/Verein" element={<Verein />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/Teams" element={<Teams />} />
          <Route path="/Shop" element={<Shop />} />
          
          <Route 
            path="/Datenschutzerklärung"
            element={<Datenschutzerklärung />}
          />
          <Route path="/U18" element={<U18 />} />
          <Route path="/U16" element={<U16 />} />
          <Route path="/Jugendmannschaften" element={<Jugendmannschaften />} />
          <Route path="/Kampfmannschaft" element={<Kampfmannschaft />} />
          <Route path="/Geschichte" element={<Geschichte />} />
          <Route path="*" element={() => <Navigate to="/" />} />
          <Route path="/Vorstand" element={<Vorstand />} />
        </Routes>
        <Footer />
      </Router>
      {/* 
      <div className="App">
        <form
          action="http://localhost:8000/server.php"
          method="post"
          onSubmit={(event) => handleSumbit(event)}
        >
          <label htmlFor="name">Name: </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(event) => handleChange(event)}
          />
          <br />
          <button type="submit">Submit</button>
        </form>
        <h1>{result}</h1>
      </div> */}
    </>
  );
}

export default App;
